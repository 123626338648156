import {Link} from "gatsby";
import React, {FC} from "react";
import {imprintSlug, privacyPolicySlug} from "../../helpers/slug-helper";
import ExtLink from "../ext-link";

const PageFooter: FC = () => (
    <footer className="bottom-0 flex-wrap bg-gray-900 text-gray-300 justify-center items-center p-6 z-footer grid grid-cols-3">
        <div className="col-span-3 lg:col-start-2 lg:col-span-1 text-center">
            <span className="mr-1">Made by </span>
            <ExtLink href="https://saschaklatt.dev">{"Sascha Klatt"}</ExtLink>
        </div>
        <div className="col-span-3 lg:col-start-3 lg:col-span-1 text-center lg:text-right mt-4 lg:mt-0">
            <Link to={imprintSlug()} className="text-gray-500 text-sm lg:mr-4 block lg:inline-block">
                {"Imprint"}
            </Link>
            <Link to={privacyPolicySlug()} className="text-gray-500 text-sm">
                {"Privacy Policy"}
            </Link>
        </div>
    </footer>
);

export default PageFooter;
