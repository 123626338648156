import React, {FC} from "react";
import cn from "classnames";

interface GridlLogoProps {
    className?: string;
}

const GridlLogo: FC<GridlLogoProps> = ({className}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={cn("h-12", "w-12", "fill-current", className)}
        fillRule="evenodd"
        strokeLinejoin="round"
        viewBox="0 0 800 800"
    >
        <path
            d="M541.453 262.144c-9.196-17.323-28.796-16.671-19.277 4.028 14.613 31.777 13.771 54.216-.053 88.04-7 17.128 12.891 25.013 19.33 6.708 15.649-44.484 11.59-76.945 0-98.776z"
            transform="matrix(1.32124 -.71966 .7233 1.32792 -302.27 236.265)"
        />
        <path
            d="M537.869 261.645c-5.896-10.08-15.118-8.905-9.274 1.957 16.965 31.534 13.373 60.26-1.088 94.674-3.08 7.329 4.669 12.287 10.157.591 20.031-42.691 12.683-75.886.205-97.222z"
            transform="matrix(2.95886 -1.61165 1.6198 2.97382 -1381.84 163.782)"
        />
        <path
            d="M470 309.875C470 291.179 454.821 276 436.125 276h-332.25C85.179 276 70 291.179 70 309.875v332.25C70 660.821 85.179 676 103.875 676h332.25C454.821 676 470 660.821 470 642.125v-332.25zm-40 197.981c0-6.543-5.313-11.856-11.856-11.856H301.856c-6.543 0-11.856 5.313-11.856 11.856v116.288c0 6.543 5.313 11.856 11.856 11.856h116.288c6.543 0 11.856-5.313 11.856-11.856V507.856zm-180 0c0-6.543-5.313-11.856-11.856-11.856H121.856c-6.543 0-11.856 5.313-11.856 11.856v116.288c0 6.543 5.313 11.856 11.856 11.856h116.288c6.543 0 11.856-5.313 11.856-11.856V507.856zm180-180c0-6.543-5.313-11.856-11.856-11.856H301.856c-6.543 0-11.856 5.313-11.856 11.856v116.288c0 6.543 5.313 11.856 11.856 11.856h116.288c6.543 0 11.856-5.313 11.856-11.856V327.856zm-180 0c0-6.543-5.313-11.856-11.856-11.856H121.856c-6.543 0-11.856 5.313-11.856 11.856v116.288c0 6.543 5.313 11.856 11.856 11.856h116.288c6.543 0 11.856-5.313 11.856-11.856V327.856z"
            transform="scale(1.14707) rotate(15 518.904 326.037)"
        />
    </svg>
);

export default GridlLogo;
