import {useStaticQuery, graphql} from "gatsby";
import React, {FC} from "react";
import {Helmet} from "react-helmet";

interface SeoProps {
    title?: string;
    description: string;
    lang?: string;
    meta?: React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>[];
}

const Seo: FC<SeoProps> = (props) => {
    const {description, lang = "en", meta = [], title} = props;

    const {site} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    author {
                        name
                        web
                        twitter
                    }
                }
            }
        }
    `);

    const metaDescription = description || site.siteMetadata.description;
    const author = `${site.siteMetadata.author.name}`;
    const twitter = `${site.siteMetadata.author.twitter}`;
    const keywords = site.siteMetadata.keywords.join(`, `);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            meta={createMeta(title || site.siteMetadata.title, metaDescription, meta, keywords, author, twitter)}
            title={title || site.siteMetadata.title}
            titleTemplate={title ? `%s | ${site.siteMetadata.title}` : undefined}
        />
    );
};

const createMeta = (title: string, metaDescription: string, meta: any[], keywords: string, author: string, twitter: string) => {
    const result = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            name: `author`,
            content: author,
        },
        {
            name: `keywords`,
            content: keywords,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: twitter,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
        ...meta,
    ];

    return result;
};

export default Seo;
