import React, {FC, useState} from "react";
import {Link} from "gatsby";
import cn from "classnames";
import GridlLogo from "../gridl-logo";
import ExtLink from "../ext-link";
import {apiDocsSlug, gettingStartedSlug} from "../../helpers/slug-helper";
import {useGridlVersion} from "../../hooks/use-gridl-version";

const BrandItem: FC = () => {
    const gridlVersion = useGridlVersion();
    return (
        <Link
            to="/"
            className="flex items-center flex-shrink-0 mr-6 font-semibold font-serif text-xl text-teal hover:no-underline"
        >
            <GridlLogo />
            <div className="flex items-baseline">
                <span className="ml-4">gridl</span>
                <span className="ml-2 text-xs">{`v${gridlVersion}`}</span>
            </div>
        </Link>
    );
};

interface MenuToggleProps {
    onClick: () => void;
}

const MenuToggle: FC<MenuToggleProps> = ({onClick}) => (
    <div className="flex justify-end flex-1 md:hidden">
        <button onClick={onClick} className="flex items-center px-3 py-2 border rounded text-white border-white">
            <svg className="w-3 h-3 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
        </button>
    </div>
);

interface NavItemsProps {
    isVisible: boolean;
    className?: string;
}

const NavItems: FC<NavItemsProps> = ({className, isVisible}) => (
    <div className={cn(`w-full ${isVisible ? "block" : "hidden"} flex-grow md:flex md:items-center md:w-auto`, className)}>
        <div className="md:flex md:flex-grow md:justify-end">
            <Link to={gettingStartedSlug()} className="btn-main-nav" activeClassName={"text-teal"} partiallyActive={true}>
                Getting started
            </Link>
            <Link to={apiDocsSlug()} className="btn-main-nav" activeClassName={"text-teal"} partiallyActive={true}>
                API Docs
            </Link>
            <ExtLink href="https://github.com/klattiation/gridl" className="btn-main-nav">
                GitHub
            </ExtLink>
            <ExtLink href="https://www.npmjs.com/package/gridl" className="btn-main-nav">
                NPM
            </ExtLink>
        </div>
    </div>
);

const MainNav: FC = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => void setMenuOpen((isMenuOpen) => !isMenuOpen);
    return (
        <nav className="flex flex-wrap items-center justify-between px-6 py-4 bg-gray-900 shadow-md fixed z-header w-full">
            <BrandItem />
            <MenuToggle onClick={toggleMenu} />
            <NavItems isVisible={isMenuOpen} />
        </nav>
    );
};

export default MainNav;
