import {graphql, useStaticQuery} from "gatsby";

export const useGridlVersion = () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        gridl {
                            latestVersion
                        }
                    }
                }
            }
        `,
    );
    return data.site.siteMetadata.gridl.latestVersion;
};
